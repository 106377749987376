import React, { useState } from "react";
import SearchForm from "./SearchForm";
import FilterSection from "./FilterSection";
import MainSection from "./MainSection";
import Chart from "./Chart";

export default function Home({ reviews, jobDetails, initialResult }) {
  const [selectedApps, setSelectedApps] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedShopifyPlans, setSelectedShopifyPlans] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedReview, setSelectedReview] = useState();
  const [selectedPlan, setSelectedPlan] = useState();

  const [searchIn, setSearchIn] = useState([]);

  const [selectedAppBlock, setSelectedAppBlock] = useState();

  const [selectedInstall, setSelectedInstall] = useState();

  const [storeCreatedAfter, setStoreCreatedAfter] = useState("");
  const [storeCreatedBefore, setStoreCreatedBefore] = useState("");

  const [mainResult, setMainResult] = useState(initialResult);
  const [loading, setLoading] = useState(false);

  const [isPasswordProtected, setIsPasswordProtected] = useState();

  const urlParams = new URLSearchParams(window.location.search);
  // Access a specific parameter
  const value = urlParams.get("q");
  const [query, setQuery] = useState(value ? value : "");
  return (
    <>
      <div className="d-flex gap-10">
        <FilterSection
          searchIn={searchIn}
          setSearchIn={setSearchIn}
          selectedApps={selectedApps}
          setSelectedApps={setSelectedApps}
          selectedCountries={selectedCountries}
          setSelectedCountries={setSelectedCountries}
          selectedShopifyPlans={selectedShopifyPlans}
          setSelectedShopifyPlans={setSelectedShopifyPlans}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedReview={selectedReview}
          setSelectedReview={setSelectedReview}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          storeCreatedAfter={storeCreatedAfter}
          setStoreCreatedAfter={setStoreCreatedAfter}
          storeCreatedBefore={storeCreatedBefore}
          setStoreCreatedBefore={setStoreCreatedBefore}
          selectedAppBlock={selectedAppBlock}
          setSelectedAppBlock={setSelectedAppBlock}
          selectedInstall={selectedInstall}
          setSelectedInstall={setSelectedInstall}
          isPasswordProtected={isPasswordProtected}
          setIsPasswordProtected={setIsPasswordProtected}
        />
        <div style={{ background: "#FFF", width: "100%" }}>
          <SearchForm
            loading={loading}
            setLoading={setLoading}
            searchIn={searchIn}
            setSearchIn={setSearchIn}
            setMainResult={setMainResult}
            selectedApps={selectedApps}
            setSelectedApps={setSelectedApps}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            selectedShopifyPlans={selectedShopifyPlans}
            setSelectedShopifyPlans={setSelectedShopifyPlans}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            selectedReview={selectedReview}
            setSelectedReview={setSelectedReview}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            storeCreatedAfter={storeCreatedAfter}
            setStoreCreatedAfter={setStoreCreatedAfter}
            storeCreatedBefore={storeCreatedBefore}
            setStoreCreatedBefore={setStoreCreatedBefore}
            selectedAppBlock={selectedAppBlock}
            setSelectedAppBlock={setSelectedAppBlock}
            selectedInstall={selectedInstall}
            setSelectedInstall={setSelectedInstall}
            isPasswordProtected={isPasswordProtected}
            query={query}
            setQuery={setQuery}
          />
          <div className="data-container">
            {mainResult.length === 0 && loading === false ? (
              <Chart reviews={reviews} jobDetails={jobDetails} />
            ) : (
              <MainSection
                mainResult={mainResult}
                loading={loading}
                query={query}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
