import React, { useEffect, useState } from "react";

const SearchForm = ({
  setLoading,
  searchIn,
  setSearchIn,
  setMainResult,
  selectedApps,
  selectedCountries,
  selectedShopifyPlans,
  selectedCategories,
  selectedReview,
  selectedPlan,
  storeCreatedAfter,
  storeCreatedBefore,
  selectedAppBlock,
  selectedInstall,
  isPasswordProtected,
  query,
  setQuery,
}) => {
  const [search, setSearch] = useState("all");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const constructQuery = () => {
    const apps = selectedApps.length
      ? selectedApps.map((e) => e.value).join()
      : "";
    const countries = selectedCountries.length
      ? selectedCountries.map((e) => e.value).join()
      : "";
    const shopifyPlans = selectedShopifyPlans.length
      ? selectedShopifyPlans.map((e) => e.value).join()
      : "";
    let categories = selectedCategories.length
      ? selectedCategories.map((e) => e.value).join()
      : "";
    categories = encodeURIComponent(categories);
    const review = selectedReview ? selectedReview.value : "";
    let plan = selectedPlan ? selectedPlan.value : "";
    plan = encodeURIComponent(plan);
    const appBlock = selectedAppBlock ? selectedAppBlock.value : "";
    const install = selectedInstall ? selectedInstall.value : "";
    const passwordProtected = isPasswordProtected
      ? isPasswordProtected.value
      : "";
    const search = searchIn ? searchIn.value : "";
    const isDev = window.location.pathname.includes("dev") ? true : false;
    return `?dev=${isDev}&q=${query.trim()}&search=${search}&apps=${apps}&countries=${countries}&shopify_plans=${shopifyPlans}&store_categories=${categories}&review=${review}&plan=${plan}&created_after=${storeCreatedAfter}&created_before=${storeCreatedBefore}&app_block_status=${appBlock}&install=${install}&password_protected=${passwordProtected}`;
  };

  const fetchData = async () => {
    setLoading(true);
    const query_params = constructQuery();
    try {
      const response = await fetch(`/fetch-data${query_params}`, {
        method: "GET",
        credentials: "include", // Include cookies in the request
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setMainResult(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}${query_params}`
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  return (
    <div className="search-section">
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group col-md-8 d-flex gap-10">
            <input
              type="text"
              value={query}
              onChange={handleQueryChange}
              className="form-control"
              placeholder="Enter data to search..."
              name="shop"
              autoFocus
            />
            <button type="submit" className="btn btn-md btn-primary">
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchForm;
